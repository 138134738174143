<template>
  <CommonPage id="blogs" title="The Book of Answer" classs="blogs">
    <section class="home-content pc_padding p-t-60">
      <div class="time-page-card-container">
        <div class="time-page-card-row">
          <div v-for="(data,i) in card" :key="i" class="time-page-card-item"
            @click="goto(data.routename)">
            <div class="time-page-card-image">
              <img :src="data.img" :alt="data.title">
            </div>
            <div class="time-page-card-content">
              <div class="time-page-card-title">
                {{ data.title }}
              </div>
              <div class="time-page-card-desc">
                {{ data.desc }}
              </div>
            </div>
            <div class="time-page-card-btn">
              Read more
            </div>
          </div>
        </div>
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';
import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    CommonPage
  },
  mixins: [common],
  data () {
    return {
			card: [
				{
					img: require('@/assets/i1.png'),
					title: '"The Book of Answers": Transformative Success Stories',
					desc: "In today's fast-paced, information-saturated world, where social media is omnipresent, many individuals find themselves feeling lost and anxious in their pursuit of success. However, 'The Book of Answers' has served as a unique tool to guide countless individuals towards clarity. It is more than just a guide; it is a transformative tool for a lifestyle shift, enabling people to pursue happiness and achievement with a stronger sense of purpose. ",
					routename: 'blog1',
				},
				{
					img: require('@/assets/i2.png'),
					title: "The Book of Answers: A Beacon of Wisdom and Guidance for Life's Crucial Decisions",
					desc: "The Book of Answers is not just a collection of wisdom but a guiding light in the lives of many readers. Through real-life customer stories, we see how this book has helped individuals make the right decisions at pivotal moments, whether in career choices, emotional decisions, or repairing family relationships. When Li Lei faced a career dilemma, he turned to the Book of Answers and found a simple yet profound message that helped him choose the right path. Similarly, Ms. Zhang, through the book’s advice, repaired her strained relationship with her husband. These success stories show that the Book of Answers is more than just a book; its concise and deep insights have helped countless readers navigate their life's challenges.",
					routename: 'blog2',
				},
				{
					img: require('@/assets/i3.png'),
					title: 'The Accuracy of The Book of Answers: Unveiling the Power of this Mysterious Book',
					desc: "The Book of Answers is a fascinating and mysterious book that has captured the attention of many seeking guidance in life. From personal dilemmas and emotional uncertainties to career choices and everyday decisions, The Book of Answers provides brief responses that help individuals navigate life’s uncertainties. But how accurate are its predictions? Why do so many claim that this book leads them in the right direction? In this article, we will explore the accuracy of The Book of Answers and analyze its unique role in psychology, philosophy, and personal growth.",
					routename: 'blog3',
				},
				{
					img: require('@/assets/i4.png'),
					title: 'How to Interpret The Book of Answers – Unveiling the Wisdom Behind It',
					desc: "The Book of Answers is a unique tool with a mysterious allure, frequently used by those seeking answers to life's questions. Whether it's emotional dilemmas, career decisions, or everyday uncertainties, the book provides brief yet impactful responses that guide readers toward reflection and self-discovery. Its charm doesn't just lie in the simplicity of its answers but in how one interprets and applies these insights. This article will explore how to interpret The Book of Answers, using SEO-optimized keywords like “The Book of Answers meaning,” “how accurate is The Book of Answers,” and “how to use The Book of Answers,” while also demonstrating its role in fostering personal growth and decision-making.",
					routename: 'blog4',
				},
				{
					img: require('@/assets/i5.png'),
					title: 'Industry Insight Report: Trends and Insights of "The Book of Answers"',
					desc: "In today's rapidly evolving knowledge economy, the ability to acquire and interpret information is becoming increasingly important. Particularly in areas such as self-improvement, mental health, and interpersonal relationships, people's craving for spiritual nourishment has made various psychology and philosophy books popular. 'The Book of Answers' as a classic work that explores a variety of life questions, has gained widespread attention for its profound wisdom and practical guidance. This report will analyze current industry trends based on data related to 'The Book of Answers' and showcase our company’s expertise in this field",
					routename: 'blog5',
				},
				{
					img: require('@/assets/i6.png'),
					title: 'The Book of Answers: Common Questions and Unique Responses, and Why People Are Drawn to Divination from a Psychological Perspective',
					desc: "The Book of Answers is a fascinating and interactive divination tool that provides brief yet insightful responses to common life questions. It not only offers philosophical guidance but also draws people seeking clarity and inner reflection. In this article, we will explore The Book of Answers from two angles: first, by listing some common questions and showcasing the book’s unique and detailed responses; second, by analyzing from a psychological perspective why people are drawn to divination books like The Book of Answers and how these tools help them better understand their decisions.",
					routename: 'blog6',
				},

			],
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    goto (name) {
      this.$router.push({ name: name });
    }
  },
}
</script>
